@import 'src/App';
.orgchart {
  background-image: none;
}

.orgchart-container {
  background-color: transparent;
  border: none;
  padding-right: 1rem;
  padding-left: 1rem;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.orgchart ul li .oc-node {
  &:hover {
    background-color: transparent;
  }

  &.selected {
    background-color: transparent;
  }

  width: 14vw;
}

.orgchart ul li .oc-node:not(:only-child)::after {
  background-color: $info;
}

.orgchart > ul > li > ul li::before {
  background-color: $info;
  border-top: 2px solid $info;
}

.orgchart > ul > li > ul li > .oc-node::before {
  background-color: $info;
}
