$base-theme: Default;
$skin-name: U Group Dashboard;
$swatch-name: Moonlight;
$border-radius: 2px;
$accent: #3d4c67;
$info: #0c779b;
$success: #2b893c;
$warning: #ea9d07;
$error: #be5138;
$text-color: #ffffff;
$bg-color: #2f3640;
$base-text: #ffffff;
$base-bg: #282f36;
$hovered-text: #ffffff;
$hovered-bg: #40444f;
$selected-text: #ffffff;
$selected-bg: #46597a;
$series-a: #0275d8;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;

td {
  text-align: right !important;
}

tr {
  text-align: center !important;
}

.k-i-sort-desc-sm::before,
.k-i-sort-asc-sm::before,
.k-sort-order {
  color: #c4c4c4 !important;
}

@import '~@progress/kendo-theme-default/dist/all.scss';

.k-panelbar > .k-item > .k-link {
  color: white;
}

.rs-picker-cascader-menu-items {
  padding-top: 0;
}

.rs-picker-menu .rs-picker-search-bar {
  margin-top: 12px;
}
