.k-panelbar > .k-item > .k-link {
  &:hover {
    cursor: pointer;
  }
}


#custom-group-name-input::placeholder{
  color: grey
}
